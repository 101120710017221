import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { IManagementAreaDetail } from '../../models/IManagementAreaDetail';
import styles from "./ManagementAreaDetail.module.css";
import settings from '../../config';
import { fetchWithAuthorisationHeader } from '../../services/AuthenticationService';
import LinearProgress from '@mui/material/LinearProgress/LinearProgress';
import Grid from '@mui/material/Grid/Grid';
import Typography from '@mui/material/Typography/Typography';

interface IManagementAreaDetailProps {
    managementAreaId: number;
}

export function ManagementAreaDetail(props: IManagementAreaDetailProps) {

    const [managementAreaDetail, setManagementAreaDetail] = useState<IManagementAreaDetail | null>(null);

    useEffect(() => {
        async function getManagementAreaDetail() {
            try {
                var response = await fetchWithAuthorisationHeader(settings.CMPLY_API_URL + "managementArea/detail/" + props.managementAreaId);
                    
                setManagementAreaDetail(response.data as IManagementAreaDetail);
            }
            catch (e) {
                console.log(e);
                setManagementAreaDetail(null);
            }
        }
        setManagementAreaDetail(null);
        if (props.managementAreaId > 0){
            getManagementAreaDetail();
        }
    }, [props.managementAreaId]);

    return <div style={{ height: '100%' }}>
        <Box display="flex" style={{textAlign: 'left', fontWeight: 400,  height: '100%'}}>             
            {  managementAreaDetail == null &&
                <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" style={{ height: '100%' }}>
                    <LinearProgress style={{ width: '260px', color: 'red', marginBottom: '8px' }} />
               </Box>
            }
            {  managementAreaDetail != null &&
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Box className={styles.itemHeader}>
                            <Typography>
                                Management Area
                            </Typography>                            
                        </Box>
                        <Box className={styles.itemContent}>
                            <Typography variant="h5">
                                {managementAreaDetail.managementAreaName}
                            </Typography>   
                        </Box>                        
                    </Grid>   
                    <Grid item xs={6}>
                        <Box className={styles.itemHeader}>
                            <Typography>
                                Region
                            </Typography>                            
                        </Box>
                        <Box className={styles.itemContent}>
                            <Typography variant="h5">
                                {managementAreaDetail.regionName}
                            </Typography>   
                        </Box>                        
                    </Grid>                     
                    <Grid item xs={6}>
                        <Box className={styles.itemHeader}>
                            <Typography>
                                Type
                            </Typography>                            
                        </Box>
                        <Box className={styles.itemContent}>
                            <Typography variant="h5">
                                {managementAreaDetail.type}
                            </Typography>   
                        </Box>                        
                    </Grid>                     
                </Grid>
            }
        </Box>
    </div>
}
