import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import { IProgramManagementArea } from '../../../../../models/IProgramManagementArea';
import { SimpleDisplayCountWidget } from '../../../Shared/Widgets/SimpleDisplayCountWidget/SimpleDisplayCountWidget';

interface IProps {
    programManagementAreas: IProgramManagementArea[];
    programCount: number;
}

export function NotScheduledManagementAreasWidget(props: IProps) {

    const topText = 'MAs Not Scheduled';
    const height = 120;
    const width = 160;
    const color = "#47211B";
    const [bottomText, setBottomText] = useState('')
    const [count, setCount] = useState(0);
  
    useEffect(() => {

            let notScheduledProgramManagementAreaCount = props.programManagementAreas.filter(x => x.scheduledDueDate == null || x.scheduledDueDate == '').length;
            setCount(notScheduledProgramManagementAreaCount);

            setBottomText("Across " + props.programCount + " program" + (props.programCount > 1 ? 's' : ''));
     
     }, [props.programManagementAreas, props.programCount]);

    return <Card sx={{ height: "100%" }}>
                <SimpleDisplayCountWidget
                    height={height}
                    width={width}
                    color={color}
                    topText={topText}
                    count={count}
                    bottomText={bottomText}/>
          </Card>
}