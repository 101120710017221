import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import settings from '../../../../config';
import { fetchWithAuthorisationHeader } from '../../../../services/AuthenticationService';

import { SpanProgress } from '../Widgets/SpanProgress/SpanProgress';
import { IDashboardFilters } from '../../../../models/IDashboardFilters';

// To Do: Replace this with filter props 
interface IProps {
    cycleId: string;
    filters: IDashboardFilters;
    chartBackgroundColor:string;
}

export function NetworkHealthContainer(props: IProps) {

    // Handle when the cycle has changed
    useEffect(() => {
        async function getReport() {
            try {
                var response = await fetchWithAuthorisationHeader(settings.CMPLY_API_URL + "dashboard/managementAreasPerRegion/" + props.cycleId);
                    
                let reportDatas = response.data as any[];

                // To do: The below code should be deleted and the data should be constructed according to how the widget's needs.

                // Region names are used for the labels and managementAreaCounts is the data
                // data is [{ regionName, managementAreaCount }]
                let chartLabels: string[] = [];
                let chartData: number[] = [];

                for (let reportData of reportDatas){
                    chartLabels.push( reportData.regionName );
                    chartData.push( parseInt(reportData.managementAreaCount) );
                }
            }
            catch (e) {
                console.log(e);
            }
        }
        if (props.cycleId != '0'){
            getReport();
        }
    }, [props.cycleId]);

    return <Card sx={{ height: "100%" }}>
            <Box sx={{ height: "100%"}}>
                <Box ml={3} mr={3}>
                    <Grid container direction="column" >
                        <Grid item>
                                <Grid item xs={12} md={6} lg={4}>
                                    <Box mb={3} sx={{ height: "50%", borderRadius: '5px'}}>
                                        <SpanProgress cycleId={props.cycleId} chartBackgroundColor={'#4DA851'} />
                                    </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
          </Card>
}