import { createTheme } from '@mui/material/styles';
import { yellow,red,green } from "@mui/material/colors";

// Main theme for Cmply
// Defines the colours, fonts, spacing etc used by Material Ui components
// Values here are global across the app but can be overridden in components
// The theme is applied at root level in App.tsx and thus applies to all components.  It does not need to be imported into components.

// See https://mui.com/material-ui/customization/theming/ for how theming works in material ui
// The properties here override the default theme which is defined here https://mui.com/material-ui/customization/default-theme/

// The main ui changes will be made to the palette object.  See https://mui.com/material-ui/customization/palette/ for details

export const theme = createTheme({
    palette: {
        mode: 'dark',
        primary:{
            main: '#C7E49E',
            light: '#DEEFC4',
            dark: '#9ED155',
            contrastText: '#000000',
        },
        secondary:{
            main: '#F7F4A8',
            light: '#FAF9CA',
            dark: '#F2EC6F',
            contrastText: '#000000',
        },
        background: {
            paper: '#161616',
            default: '#161616',
        }
    }
});