import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import { IProgramManagementArea } from '../../../../../models/IProgramManagementArea';
import { SimpleDisplayCountWidget } from '../../../Shared/Widgets/SimpleDisplayCountWidget/SimpleDisplayCountWidget';

interface IProps {
    programManagementAreas: IProgramManagementArea[];
}

export function CompliantManagementAreasWidget(props: IProps) {

    const topText = 'MAs Compliant';
    const height = 120;
    const width = 160;
    const color = "#222222";
    const [bottomText, setBottomText] = useState('');
    const [count, setCount] = useState(0);

    useEffect(() => {

        let compliantProgramManagementAreas = props.programManagementAreas.filter(x => x.complianceDate != null && x.complianceDate != '');
        setCount(compliantProgramManagementAreas.length);

        let practicallyCompletedProgramManagementAreaCount = compliantProgramManagementAreas.filter(x => x.practicalCompletionDate != null && x.practicalCompletionDate != '').length;

        setBottomText(practicallyCompletedProgramManagementAreaCount + " Prac. Complete");

    }, [props.programManagementAreas]);

    return <Card sx={{ height: "100%" }}>
        <SimpleDisplayCountWidget
            height={height}
            width={width}
            color={color}
            topText={topText}
            count={count}
            bottomText={bottomText} />
    </Card>
}