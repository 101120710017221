import React from "react";
import logo from "./logo.svg";
import { BrowserRouter as Router } from "react-router-dom";

import "./App.css";
import { CmplyContainer } from "./components/Containers/CmplyContainer/CmplyContainer";

import { ThemeProvider } from '@mui/material/styles';

import { theme } from './themes/Cmply';

function App() {
  return (
      <ThemeProvider theme={theme}>
        <Router>
          <div className="App">
            <CmplyContainer />
          </div>
        </Router>
      </ThemeProvider>
  );
}

export default App;
