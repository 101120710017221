import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import styles from './FilterBar.module.css';
import { AppBar, Button, Grid, Toolbar } from '@mui/material';

import CycleSelector from '../../CycleSelector/CycleSelector';
import DashboardRegionFilter from '../Filters/DashboardRegion/DashboardRegionFilter';
import DashboardTypeFilter from '../Filters/DashboardType/DashboardTypeFilter';
import DashboardProgramFilter from '../Filters/DashboardProgram/DashboardProgramFilter';
import { IDashboardFilters } from '../../../models/IDashboardFilters';

interface IFilterBarProps {
  cycleId: string;
  userFirstName: string | null;
  getFilterVals: Function;
  HasNoDashboardData: Function;
}

export function FilterBar(props: IFilterBarProps) {
  const [selectedRegionIds, setSelectedRegionIds] = useState<number[]>([]);
  const [selectedMATypes, setSelectedMATypes] = useState<string[]>([]);
  const [selectedProgNameIds, setSelectedProgNameIds] = useState<number[]>([]);
  const [selectedProgTypeIds, setSelectedProgTypeIds] = useState<number[]>([]);

  const [regionData, setRegionData] = useState(true);
  const [typeData, setTypeData] = useState(true);
  const [programData, setProgramData] = useState(true);

  const defaultFilterValues: IDashboardFilters = {
    cycleId: '0',
    regionIds: [],
    managementAreaTypes: [],
    programNameIds: [],
    programTypeIds: [],
  };

  const [filterValues, setFilterValues] = useState(defaultFilterValues);

  const onRegionIdChange = (selectedOptions: any[]) => {
    setSelectedRegionIds(selectedOptions.map((x) => x.id));
  };

  const onMATypeChange = (selectedOptions: any[]) => {
    setSelectedMATypes(selectedOptions.map((x) => x.type));
  };

  const onProgramChange = (selectedOptions: any[]) => {
    setSelectedProgNameIds(selectedOptions.map((x) => x.programId));
    setSelectedProgTypeIds(selectedOptions.map((x) => x.programTypeId));
  };

  const filterHasNoData = (filter: string, noData: boolean) => {
    switch (filter) {
      case 'Region':
        setRegionData(!noData);
        break;
      case 'Type':
        setTypeData(!noData);
        break;
      case 'Program':
        setProgramData(!noData);
        break;
    }
  };

  useEffect(() => {
    props.getFilterVals(filterValues);
  }, [filterValues]);

  const onApplyFilter = () => {
    if (props.cycleId !== '0' && selectedRegionIds.length > 0 && selectedMATypes.length > 0 && selectedProgNameIds.length > 0 && selectedProgTypeIds.length > 0) {
      let filterVals: IDashboardFilters = {
        cycleId: props.cycleId,
        regionIds: selectedRegionIds,
        managementAreaTypes: selectedMATypes,
        programNameIds: selectedProgNameIds,
        programTypeIds: selectedProgTypeIds,
      };

      setFilterValues(filterVals);

      localStorage.setItem('cycleId', props.cycleId);
      localStorage.setItem('selectedRegionIds', JSON.stringify(selectedRegionIds));
      localStorage.setItem('managementAreaTypes', JSON.stringify(selectedMATypes));
      localStorage.setItem('programNameIds', JSON.stringify(selectedProgNameIds));
    } else {
      setFilterValues(defaultFilterValues);
    }
  };

  let isFilterDisabled = !regionData || !typeData || !programData;
  props.HasNoDashboardData(isFilterDisabled);

  return (
    <AppBar className={styles.dashboardBar} position="static" elevation={3} style={{ background: 'transparent', boxShadow: 'none' }}>
      <div style={{ width: '100%', display: 'flex' }}>
        <div style={{ width: '300px' }}>
          <Box sx={{ fontSize: 20, display: 'box' }} className={styles.dashboardTitle}>
            Dashboard
          </Box>
          <Box sx={{ fontSize: 16, display: 'box', paddingTop: '10px' }} className={styles.dashboardUser}>
            Hi {props.userFirstName}, here are today's updates
          </Box>
        </div>
        <div style={{ display: 'flex' }}>
          <Box>
            <DashboardRegionFilter filterHasNoData={filterHasNoData} onRegionIdChange={onRegionIdChange} selectedCycleId={props.cycleId} />
          </Box>
          <Box sx={{ paddingLeft: '40px' }}>
            <DashboardTypeFilter filterHasNoData={filterHasNoData} onMATypeChange={onMATypeChange} selectedCycleId={props.cycleId} />
          </Box>
          <Box sx={{ paddingLeft: '40px' }}>
            <DashboardProgramFilter filterHasNoData={filterHasNoData} onProgramChange={onProgramChange} selectedCycleId={props.cycleId} />
          </Box>
        </div>
        <Grid item>
          <Box justifyContent="flex-end" style={{ marginTop: 8 }}>
            <Button size="large" color="secondary" variant="contained" style={{ marginLeft: 24, marginTop: 4 }} disabled={isFilterDisabled} onClick={() => onApplyFilter()}>
              APPLY
            </Button>
          </Box>
        </Grid>
      </div>
    </AppBar>
  );
}
