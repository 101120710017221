import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import settings from '../../../../config';

import { postWithAuthorisationHeader } from '../../../../services/AuthenticationService';
import { CompletedManagementAreasWidget } from '../Widgets/CompletedManagementAreasWidget/CompletedManagementAreasWidget';
import { CompliantManagementAreasWidget } from '../Widgets/CompliantManagementAreasWidget/CompliantManagementAreasWidget';
import { InProgressManagementAreas } from '../Widgets/InProgressManagementAreas/InProgressManagementAreas';
import { NotScheduledManagementAreasWidget } from '../Widgets/NotScheduledManagementAreasWidget/NotScheduledManagementAreasWidget';
import { IDashboardFilters } from '../../../../models/IDashboardFilters';
import { IProgramManagementArea } from '../../../../models/IProgramManagementArea';


// To Do: Replace this with filter props 
interface IProps {
    filters: IDashboardFilters;
    chartBackgroundColor:string;
}

export function ManagementAreaProgressContainer(props: IProps) {
    const defaultProgramManagementAreas: IProgramManagementArea[] = [];
    const [programManagementAreas, setProgramManagementAreas] = useState(defaultProgramManagementAreas);

    // Handle when the cycle has changed
    useEffect(() => {
        async function getProgramManagementAreas() {
            try {
                var response = await postWithAuthorisationHeader(settings.CMPLY_API_URL + "dashboard/programManagementAreas/", props.filters);
                let responseProgramManagementAreas = response.data as IProgramManagementArea[];
                setProgramManagementAreas(responseProgramManagementAreas);
            }
            catch (e) {
                console.log('Failed to get program management areas:' + e);
            }
        }
        if (props.filters.cycleId != '0'){
            getProgramManagementAreas();
        }
    }, [props.filters]);

    return <Card sx={{ height: "100%"}}>
       
            <Box sx={{ height: "100%"}}>
            <Box>
                    <Grid container direction="column" >
                        <Grid item>
                                <Grid item >
                                    <Box mb={3} sx={{ height: "50%", borderRadius: '5px'}}>
                                        <CompletedManagementAreasWidget programManagementAreas={programManagementAreas} />
                                    </Box>
                                </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container direction="row">
                               <Grid>
                                <Box mr={3} sx={{ height: "100%", borderRadius: '5px'}}>
                                    <NotScheduledManagementAreasWidget programManagementAreas={programManagementAreas} programCount={props.filters.programNameIds.length} />
                                </Box>
                                </Grid>
                                <Grid>
                                <Box sx={{ height: "180%", width: "80%", borderRadius: '5px' }}>
                                    <InProgressManagementAreas cycleId={props.filters.cycleId} programManagementAreas={programManagementAreas}  chartBackgroundColor={'#4DA851'} />
                                </Box>
                                </Grid>
                                    <Grid>
                                    <Box sx={{ height: "100%", borderRadius: '5px' }}>
                                        <CompliantManagementAreasWidget programManagementAreas={programManagementAreas}/>
                                </Box>
                                 </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
   
          </Card>
}