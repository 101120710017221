import React, { useEffect } from 'react';
import settings from '../../../../config';
import { fetchWithAuthorisationHeader } from '../../../../services/AuthenticationService';
import AutoCompleteSelectAll from '../AutoCompleteSelectAll/AutoCompleteSelectAll';

interface Props {
  onProgramChange: Function;
  selectedCycleId: string;
  filterHasNoData: Function;
}

const DashboardProgramFilter = (props: Props) => {
  let emptyPrograms: any[] = [];

  /* Initialisation of the states */
  const [programs, setPrograms] = React.useState(emptyPrograms);
  const [selectedPrograms, setSelectedPrograms] = React.useState([]);
  const [iniliatised, setIniliatised] = React.useState(false); //Indicates whether the api has been called or not
  let isDisabled = programs.length === 0;

  useEffect(() => {
    const getPrograms = async () => {
      try {
        let response = await fetchWithAuthorisationHeader(settings.CMPLY_API_URL + 'Program/programNameProgramType/' + props.selectedCycleId);
        setIniliatised(true);
        if (response && response.data) {
          let data = response.data.sort((a: any, b: any) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));

          setPrograms(data);

          props.filterHasNoData('Program', data.length=== 0);

          if (window.localStorage.getItem('cycleId') === props.selectedCycleId) {
            let ids = JSON.parse(localStorage.getItem('programNameIds') || '{}');
            if (ids.length > 0) {
              setSelectedPrograms(data.filter((value: any) => ids.includes(value.programId)));
            }
          }
        }
      } catch (e) {
        console.log(e);
      }
    };
    if (props.selectedCycleId != '0') {
      getPrograms();
    }
  }, [props.selectedCycleId]);

  /*Render*/
  if (!iniliatised) return null; // Render nothing if the api is not called

  return (
    <div>
      <AutoCompleteSelectAll
        options={programs}
        selectedOptions={selectedPrograms}
        isDisabled={isDisabled}
        noOptionLabel="No programs available"
        selectLabel="Select Program - Type"
        selectedAllLabel="All Programs"
        keyName="name"
        valueName="type"
        isShowBoth={true}
        cssStyle={{ width: '290px' }}
        maxCharsInLabel={18}
        onOptionChange={props.onProgramChange}
      />
    </div>
  );
};

export default DashboardProgramFilter;
