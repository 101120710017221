import React, { useEffect } from 'react';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';


interface IProps {
    topText: string;
    bottomText: string;
    count: number;
    height: number;
    width: number;
    color: string;
}

export function SimpleDisplayCountWidget(props: IProps) {
  
    return <Card sx={{ height: props.height, width: props.width, bgcolor: props.color, textAlign:'left'}}>
                <CardContent>
                    <Typography variant="body2" color="text.primary">
                        {props.topText}
                    </Typography>
                    <Typography variant="h4" color="text.primary">
                        {props.count}
                    </Typography>
                    <Typography variant="body2" color="text.primary">
                         {props.bottomText}
                    </Typography>
                </CardContent>
            </Card>
}