import React, { useState, useEffect, createRef, useRef } from 'react';
import { DateRangePicker } from 'mui-daterange-picker';
import styles from './DateFilter.module.css';
import moment from 'moment';


import {
    addDays,
    startOfWeek,
    endOfWeek,
    addWeeks,
    startOfMonth,
    endOfMonth,
    addMonths,

} from 'date-fns';
import { DateRange } from '@mui/icons-material';

interface IDateFilterProps {
    onFilterChanged: Function;
    clearFilters: number;
    isDisplayed: boolean;
}

interface DateRange {
    startDate?: Date,
    endDate?: Date
}

interface DefinedRange {
    label: string,
    startDate: Date,
    endDate: Date
}

export function DateFilter(props: IDateFilterProps) {
    const [open, setOpen] = React.useState(true);
    const [displayDatePicker, setDisplayDatePicker] = React.useState(true);
    const [lastClearFilters, setLastClearFilters] = useState(0);
    const [dateRange, setDateRange] = React.useState<DateRange>({});

    const getRanges = (date: Date): DefinedRange[] => [
        {
            label: 'Today',
            startDate: date,
            endDate: date,
        },
        {
            label: 'Yesterday',
            startDate: addDays(date, -1),
            endDate: addDays(date, -1),
        },
        {
            label: 'This Week',
            startDate: startOfWeek(date, { weekStartsOn: 1 }),
            endDate: endOfWeek(date, { weekStartsOn: 1 }),
        },
        {
            label: 'Last Week',
            startDate: startOfWeek(addWeeks(date, -1), { weekStartsOn: 1 }),
            endDate: endOfWeek(addWeeks(date, -1), { weekStartsOn: 1 }),
        },
        {
            label: 'Last 7 Days',
            startDate: addWeeks(date, -1),
            endDate: date,
        },
        {
            label: 'This Month',
            startDate: startOfMonth(date),
            endDate: endOfMonth(date),
        },
        {
            label: 'Last Month',
            startDate: startOfMonth(addMonths(date, -1)),
            endDate: endOfMonth(addMonths(date, -1)),
        },
    ];

    const toggle = () => {
        setOpen(!open);
    }

    useEffect(() => {
        setOpen(props.isDisplayed);
        setDisplayDatePicker(props.isDisplayed);
    }, [props.isDisplayed]);

    // Handle when the filters have been cleard
    // A number is passed as a flag so we can tell when it has changed
    useEffect(() => {
        if (props.clearFilters != lastClearFilters) {
            setDateRange({});
            setLastClearFilters(props.clearFilters);
        }
    }, [props.clearFilters, lastClearFilters]);

    function onDatesSelected(range: DateRange) {
        if (range.startDate && range.endDate) {
            console.log("Dates selected in local time:", range);

            let offsettedStartDateTime = new Date(range.startDate.getFullYear(), range.startDate.getMonth(), range.startDate.getDate(), 0, 0, 0);
            let offsettedEndDateTime = new Date(range.endDate.getFullYear(), range.endDate.getMonth(), range.endDate.getDate(), 23, 59, 59);
            console.log("Offsetted start date:", offsettedStartDateTime);
            console.log("Offsetted end date:", offsettedEndDateTime);

            let startDate = moment.utc(offsettedStartDateTime).format("DD/MM/YYYY HH:mm:ss");
            let endDate = moment.utc(offsettedEndDateTime).format("DD/MM/YYYY HH:mm:ss");

            let dates: string[] = [startDate, endDate] || [];
            console.log("Dates convertedto utc:", dates);
            props.onFilterChanged(dates);
            setDisplayDatePicker(false);
        }
    }

    return (
        <div className={displayDatePicker ? '' : styles.container}>
            <DateRangePicker
                open={open}
                initialDateRange={dateRange}
                toggle={toggle}
                onChange={(range) => onDatesSelected(range)}
                closeOnClickOutside={true}
                definedRanges={getRanges(new Date())}
            />
        </div>
    );
}
