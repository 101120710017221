import React from "react";
import { Box } from "@mui/material/";
import Grid from "@mui/material/Grid";
import { IManagementArea } from "../../../models/IManagementArea";
import styles from "./ManagementAreaSearchResult.module.css"; 
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

interface IManagementAreaSearchResultProps {
    managementArea: IManagementArea;
    isManagementAreaSelected: boolean;
    onManagementAreaSelected: Function;
}

export function ManagementAreaSearchResult(props: IManagementAreaSearchResultProps) {
    if (props.managementArea == null) return null;

    let containerClasses = props.isManagementAreaSelected?  styles.container + ' ' + styles.selected : styles.container;

    let managementAreaDetailContainer: JSX.Element;

    managementAreaDetailContainer = (<Grid item
        className={styles.content}
        style={{ paddingLeft: 16 }}>
        <Grid container spacing={0} direction="row">
            <Grid item xs >
                <Box className={styles.managementAreaLabel}>
                    <Typography className={styles.bodyText} variant="body2" gutterBottom>
                        {props.managementArea.managementAreaName || "---"}
                    </Typography>
                </Box>
            </Grid>
        </Grid>
    </Grid>);

    return (
        <Grid container className={containerClasses} alignItems="center" 
            onClick={() => props.onManagementAreaSelected(props.managementArea)}
            >
               <Grid container direction="column">
                    <Grid item>
                        <Divider />
                    </Grid>
                </Grid>
            <Grid container alignItems="center">
                {managementAreaDetailContainer}
            </Grid>
        </Grid>
    );
}
