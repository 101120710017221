import React from 'react';
import Box from '@mui/material/Box';

export function Insights(props) {

    let tableauReports = [];
    let count = 0;

    props.tableauUrls.forEach(u => {
        let key = "tableau - viz" + (++count);
        tableauReports.push(
            <tableau-viz
                key={key}
                id='tableau-viz'
                src={u[1]}
                width='1175'
                height='350'
                toolbar='top'
                token={u[0]}>
            </tableau-viz>
        )
    });

    return (
        <div>
            <Box sx={{ height: "100%" }}>
                {tableauReports} 
            </Box>
        </div>
    )
}