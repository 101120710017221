// pulls settings from `public/config.js` and fallbacks to dotenv variables if not defined
const settings = {
    ENV: window.env.REACT_APP_ENV ?? process.env.REACT_APP_ENV,
    AIMS3D_URL: window.env.REACT_APP_AIMS3D_URL ?? process.env.REACT_APP_AIMS3D_URL,
    AIMS3D_API_URL: window.env.REACT_APP_AIMS3D_API_URL ?? process.env.REACT_APP_AIMS3D_API_URL,
    VAA_URL: window.env.REACT_APP_VAA_URL ?? process.env.REACT_APP_VAA_URL,
    VAA_API_URL: window.env.REACT_APP_VAA_API_URL ?? process.env.REACT_APP_VAA_API_URL,
    CMPLY_URL: window.env.REACT_APP_CMPLY_URL ?? process.env.REACT_APP_CMPLY_URL,
    CMPLY_API_URL: window.env.REACT_APP_CMPLY_API_URL ?? process.env.REACT_APP_CMPLY_API_URL,
    CLIENT_ID: window.env.REACT_APP_CLIENT_ID ?? process.env.REACT_APP_CLIENT_ID,
    CLIENT_SECRET: window.env.REACT_APP_CLIENT_SECRET ?? process.env.REACT_APP_CLIENT_SECRET,
    CONTEXTUAL_MAP_LAYER_MIN_ZOOM_LEVEL: window.env.REACT_APP_CONTEXTUAL_MAP_LAYER_MIN_ZOOM_LEVEL ?? process.env.REACT_APP_CONTEXTUAL_MAP_LAYER_MIN_ZOOM_LEVEL
};

export default settings;