import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import { BarChart } from '../../../../Charts/BarChart/BarChart';
import settings from '../../../../../config';
import { fetchWithAuthorisationHeader } from '../../../../../services/AuthenticationService';

import getChartConfig from "../../../SharedChartConfig/BarChartConfig";
import { IChartConfig } from '../../../../../models/IChartConfig';

//THIS IS A TEMPORARY SAMPLE WIDGET. CAN BE MODIFIED COMPLETELY TO SUIT THE REQUIREMENT. 

interface IProps {
    cycleId: string; 
    chartBackgroundColor:string;
}

export function SpanProgress(props: IProps) {

    const title = 'Span Progress'

    const [chartConfig, setChartConfig] = useState<IChartConfig | null>(null);

    // Handle when the cycle has changed
    useEffect(() => {

         //To do: The below api call to be deleted. The widgets will get data from the parent container as props. 
        async function getReport() {
            try {
                var response = await fetchWithAuthorisationHeader(settings.CMPLY_API_URL + "dashboard/managementAreasPerRegion/" + props.cycleId);
                    
                let reportDatas = response.data as any[];

                // Region names are used for the labels and managementAreaCounts is the data
                // data is [{ regionName, managementAreaCount }]
                let chartLabels: string[] = [];
                let chartData: number[] = [];

                for (let reportData of reportDatas){
                    chartLabels.push( reportData.regionName );
                    chartData.push( parseInt(reportData.managementAreaCount) );
                }

                // Set the chart configuration
                setChartConfig( getChartConfig(chartLabels, { label: "Management areas", data: chartData } ) );
            }
            catch (e) {
                console.log(e);
                setChartConfig(null);
            }
        }
        if (props.cycleId != '0'){
            getReport();
        }else {
            setChartConfig(null);
        }
    }, [props.cycleId]);

    return <Card sx={{ height: "100%" }}>
                { chartConfig &&
                    <Box padding="1rem">
                        <Box sx={{ height: "50%", borderRadius: '5px'}}>
                            <BarChart backgroundColor={props.chartBackgroundColor} chartConfig={chartConfig}/>
                        </Box>
                        <Box pt={3} pb={1} px={1}>
                            <Typography variant="h6">
                                {title}
                            </Typography>                            
                        </Box>    
                    </Box>
                }
           </Card>
}