import React, { useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import { ManagementAreaProgressContainer } from './ManagementAreaProgress/ManagementAreaProgressContainer/ManagementAreaProgressContainer';
import { NetworkHealthContainer } from './NetworkHealth/NetworkHealthContainer/NetworkHealthContainer';
import { AuditQualityContainer } from './AuditQuality/AuditQualityContainer/AuditQualityContainer';
import { FilterBar } from './FilterBar/FilterBar';
import { IDashboardFilters } from '../../models/IDashboardFilters';
import { Card, Paper, Stack, Typography } from '@mui/material';

import styles from './Dashboard.module.css';

interface IDashboardProps {
  cycleId: string;
  isDisplayed: boolean;
  userFirstName: string | null;
}

export function Dashboard(props: IDashboardProps) {
  const ref = useRef(false);
  const defaultFilterValues: IDashboardFilters = {
    cycleId: '0',
    regionIds: [],
    managementAreaTypes: [],
    programNameIds: [],
    programTypeIds: [],
  };

  const [isDashboard, setIsDashboard] = useState(true);
  const [filters, setFilters] = useState(defaultFilterValues);
  const [isValidFilterData, setIsValidFilterData] = useState(true);

  useEffect(() => {
    setFilters(defaultFilterValues);
  }, [props.cycleId])

  const HasNoDashboardData = (noData: boolean) => {
    setIsDashboard(!noData && isValidFilterData);
  };

  const getFilterVals = (filterValues: IDashboardFilters) => {
    setFilters(filterValues);
  };

  useEffect(() => {
    setIsValidFilterData(filters.cycleId !== '0' && filters.regionIds.length > 0 && filters.managementAreaTypes.length > 0 && filters.programNameIds.length > 0);
  }, [filters]);

  if (!ref.current && !props.isDisplayed) {
    return <div></div>;
  }

  ref.current = true;

  if (props.cycleId === '0') {
    return (
      <Grid sx={{ minWidth: '100%', minHeight: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        <div className={styles.noCycle}>
          <Typography variant="body1">Please select a cycle</Typography>
        </div>
      </Grid>
    );
  }

  let NoDashboardData = (
    <Grid sx={{ minWidth: '100%', minHeight: '80vh', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
      <div className={styles.noCycle}>
        <Typography variant="body1">No data is available for dashboard</Typography>
      </div>
    </Grid>
  );

  return (
    <Box className={styles.widgets}>
      <FilterBar HasNoDashboardData={HasNoDashboardData} cycleId={props.cycleId} userFirstName={props.userFirstName} getFilterVals={getFilterVals} />
      {isDashboard ? (
        <Box ml={3} mt={3} mr={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4}>
              <Box mb={3}>
                <ManagementAreaProgressContainer filters={filters} chartBackgroundColor={'#439DF0'} />
              </Box>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Box mb={3}>
                <NetworkHealthContainer filters={filters} cycleId={props.cycleId} chartBackgroundColor={'#4DA851'} />
              </Box>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Box mb={3}>
                <AuditQualityContainer filters={filters} cycleId={props.cycleId} chartBackgroundColor={'#F44335'} />
              </Box>
            </Grid>
          </Grid>
        </Box>
      ) : (
        NoDashboardData
      )}
    </Box>
  );
}
