//Use this component when you want a dropdown that has auto complete and option to select all.
import React, { Fragment, SyntheticEvent, useEffect, useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import { Autocomplete, AutocompleteRenderInputParams, FormControl, List, TextField, createFilterOptions, FilterOptionsState, Badge, makeStyles } from '@mui/material';
import { fetchWithAuthorisationHeader, postWithAuthorisationHeader } from '../../../../services/AuthenticationService';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import styles from './Filter.module.css';

interface Props {
  options: any[]; //all the options you want to display in dropdown
  selectedOptions: any[]; //all the options you want to display pre selcted in dropdown
  isDisabled: boolean; //if the filter is disabled (based on no data available)
  noOptionLabel: string; //message to display when no options ara available e.g. no region is available
  selectLabel: string; //message to show when no option is seleced. e.g. Please select a region.
  selectedAllLabel: string; //message to display when all options are selected. e.g. All Regions
  keyName: string; //name of the key of options and has to be unique. e.g. id.
  valueName: string; //name of the value e.g. name.
  isShowBoth: boolean; //true for showing both key and value in droopdown.
  cssStyle: any; //Css style such as height width
  maxCharsInLabel: number; //Maximum numbers of charachters to show in placeholder.
  onOptionChange: Function; //Function to call when selection is changed.
}

const AutoCompleteSelectAll = (props: Props) => {
  const KEY = props.keyName;
  const VAL = props.valueName;
  const isShowBoth = props.isShowBoth;
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  //Add select all as option only once
  if (props.options.length > 1 && props.options.filter((x) => x.id === -1).length === 0) props.options.unshift({ id: -1, [VAL]: 'Select All' });

  const [values, setValues] = React.useState(props.selectedOptions.length > 0 ? props.selectedOptions : props.options);
  const isAllOptChecked = values.filter((x) => x.id === -1).length > 0;

  useEffect(() => {
    props.onOptionChange(values.filter((x) => x.id !== -1));
  }, [values]);

  useEffect(() => {
    if (props.selectedOptions.length > 0 && values !== props.selectedOptions) 
      setValues(props.selectedOptions);
    else if (values !== props.options) 
      setValues(props.options);
  }, [props.selectedOptions, props.options]);

  const handleChange = (event: any, selectedOptions: any[]) => {
    let isAllSelected = selectedOptions.filter((x) => x.id === -1).length > 0,
      allLength = props.options.length,
      selectedLength = selectedOptions.length;
    if (allLength > 1) {
      if (isAllSelected && selectedLength === 1) {
        setValues(props.options);
      } else if (isAllSelected && allLength !== selectedLength) {
        selectedOptions.splice(selectedOptions.map((item) => item.id).indexOf(-1), 1);
        setValues(event.currentTarget.title === 'Select All' ? props.options : selectedOptions);
      } else if (selectedLength == allLength - 1) {
        setValues(event.currentTarget.title === 'Select All' ? [] : props.options);
      } else if (!isAllSelected) {
        setValues(selectedOptions);
      }
    } else {
      setValues(selectedOptions);
    }
  };

  const onRenderInput = (params: AutocompleteRenderInputParams) => {
    let inputProps = params.inputProps as any;

    return (
      <TextField
        {...params}
        label={props.isDisabled ? props.noOptionLabel : props.selectLabel}
        variant="filled"
        size="small"
        fullWidth
        disabled={props.isDisabled}
        margin="dense"
        InputLabelProps={{
          className: styles.label,
        }}
      />
    );
  };

  const renderOption = (prop: any, option: any, { selected }: any) => {
    let isOptAll = option.id === -1;
    if (props.options.length - props.selectedOptions.length === 1 && isOptAll) selected = true;
    return (
      <li {...prop} key={isOptAll ? -1 : option[KEY]} title={option[VAL]}>
        <Checkbox icon={icon} checkedIcon={checkedIcon} checked={selected} />
        {isShowBoth && !isOptAll ? option[KEY] + '- ' + option[VAL] : option[VAL]}
      </li>
    );
  };

  let badgeCount = props.options.length > 0 ? (isAllOptChecked ? values.length - 1 : values.length) : 0;

  return (
    <FormControl>
      <Badge sx={{ right: '-30px', top: '10px' }} color="secondary" overlap="circular" badgeContent={badgeCount} />
      <Autocomplete
        sx={props.cssStyle}
        multiple
        size="small"
        options={props.options}
        getOptionLabel={(option) => (isShowBoth ? option[KEY] + '- ' + option[VAL] : option[VAL])}
        ListboxComponent={List}
        renderInput={onRenderInput}
        disableCloseOnSelect
        disabled={props.isDisabled}
        value={values}
        isOptionEqualToValue={(option, value) => option[KEY] === value[KEY] && option[VAL] === value[VAL]}
        classes={{
          root: styles.root,
          inputRoot: styles.inputRoot,
          input: styles.input,
          listbox: styles.listbox,
          option: styles.listOption,
          popupIndicator: styles.indicator,
          clearIndicator: styles.indicator,
        }}
        renderOption={renderOption}
        onChange={handleChange}
        renderTags={(options) => {
          if (props.isDisabled) return;
          let name = options.map((r) => (isShowBoth ? r[KEY] + '- ' + r[VAL] : r[VAL])).join(', ');
          name = name.length > props.maxCharsInLabel ? name.substring(0, props.maxCharsInLabel) + '...' : name;

          if (options.filter((x) => x.id === -1).length > 0) name = props.selectedAllLabel;

          return <span style={{ marginLeft: '4px' }}>{name}</span>;
        }}
      />
    </FormControl>
  );
};

export default AutoCompleteSelectAll;
