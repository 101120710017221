import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';

import { IProgramManagementArea } from '../../../../../models/IProgramManagementArea';
import { HalfDoughnutWidget } from '../../../Shared/Widgets/HalfDonutWidget/HalfDoughnutWidget';
import { IChartLegend } from '../../../../../models/IChartLegend';


interface IProps {
    programManagementAreas: IProgramManagementArea[];
}

export function CompletedManagementAreasWidget(props: IProps) {

    const centerSmallText = 'Completed MAs';

    const [totalCount,setTotalCount] = useState(0);
    const [centreBigText,setCentreBigText] = useState('');
    const [topText,setTopText] = useState('');
    const [chartData,setChartData] = useState<object|null>(null);
    const [chartLegends,setChartLegends] = useState<IChartLegend[]>([]);

    // Handle when the data has changed
    useEffect(() => {

        if ( props.programManagementAreas.length === 0 )
        {
            return;
        }

        let currentDate = new Date();

        let completedCount = props.programManagementAreas.filter(pma => pma.practicalCompletionDate != null).length;
        let theTotalCount = props.programManagementAreas.length;
        let targetCount = 0;

        props.programManagementAreas.forEach( pma => {
            if ( pma.scheduledDueDate != null && currentDate > new Date(pma.scheduledDueDate) )
            {
                ++targetCount;
            }
        });

        setTopText('Target ' + targetCount.toString());
        setTotalCount(theTotalCount);
        setCentreBigText(completedCount.toString());

        setChartData( getChartData(completedCount, targetCount, theTotalCount) );

        setChartLegends( [
            {text: 'Completed', colour: '#90C9F9', borderColour: '#90C9F9' },
            {text: 'Target', colour: completedCount < targetCount ? '#434343' : '#90C9F9', borderColour: '#ffffff'}, 
            {text: 'Uncompleted',colour: '#434343', borderColour: '#434343'},
            ]);

    }, [props.programManagementAreas]);

    function tooltipFooterCallback(tooltipItems:any ){
        // This is called by ChartJs onMouseOver the chart

        // Calculate the total count then use that to get the percent that this value is

        let totalCount = 0;
        let tooltipItem = tooltipItems[0];

        for(let i=0; i < tooltipItem.dataset.data.length; ++i ){
            totalCount += tooltipItem.dataset.data[i];
        }

        let itemValue = tooltipItem.dataset.data[tooltipItem.dataIndex];

        let percent = Math.round( (itemValue/totalCount) * 100 );

        return percent + ' Percent'; 
    }

    // Returns a ChartJS data object
    function getChartData(completedCount:number, targetCount:number, totalCount:number){

        let data: any = {};
        
        let dataset: any = {};
        data.datasets = [dataset];
        dataset.borderWidth = 2;

        if ( completedCount < targetCount ){
            data.labels = ['Completed','Target Uncompleted','Uncompleted' ];
            dataset.data = [ completedCount, ( targetCount - completedCount) , (totalCount - targetCount)];
            dataset.borderColor = ['#90CAF9','white','#434343'];
            dataset.backgroundColor = ['#90CAF9', '#434343', '#434343'];
        }
        else {
            data.labels = ['Target completed','Completed','Uncompleted' ];
            dataset.data = [targetCount, (completedCount - targetCount), (totalCount - completedCount)];            
            dataset.borderColor = ['white','#90CAF9','#434343'];
            dataset.backgroundColor = ['#90CAF9', '#90CAF9', '#434343'];
        }        

        return data;
    }

    return <Card sx={{ height: "100%" }}>
                { chartData &&
                    <HalfDoughnutWidget 
                        topText={topText}
                        centreBigText={centreBigText}        
                        centerSmallText={centerSmallText} 
                        totalCount={totalCount} 
                        chartLegends={chartLegends}                        
                        chartData={chartData}
                        tooltipFooterCallback={tooltipFooterCallback}
                    ></HalfDoughnutWidget>
                }
           </Card>
}