import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import { DoughnutChart } from '../../../../Charts/DoughnutChart/DoughnutChart';
import { IChartLegend } from '../../../../../models/IChartLegend';
import { IChartConfig } from '../../../../../models/IChartConfig';

import styles from './HalfDoughnutWidget.module.css'
import getChartConfig from "./HalfDoughnutChartConfig";

interface IProps {
    topText: string;
    centerSmallText: string;
    centreBigText: string;
    totalCount:number;
    chartLegends: IChartLegend[];
    chartData: any;
    tooltipFooterCallback: Function | null;
}

export function HalfDoughnutWidget(props: IProps) {
    const [isMouseOverChart,setIsMouseOverChart] = useState(false);
    const [chartConfig, setChartConfig] = useState<IChartConfig | null>(null);

    useEffect(() => {

        let theChartConfig:any = getChartConfig(); 

        if ( props.tooltipFooterCallback != null ){
            theChartConfig.options.plugins.tooltip.callbacks.footer = props.tooltipFooterCallback;
        }

        setChartConfig({data: props.chartData, options: theChartConfig.options });
    }, [props.chartData]);

    let chartLegendItems: JSX.Element[] = []; 
    props.chartLegends.forEach( (chartLegend: IChartLegend ) => {

        chartLegendItems.push( 
            <Grid item >
                <Box style={{color:'white'}}>
                    <Typography variant="h6" style={{fontSize:12}}>                    
                        <span className={styles.dot} style={{height: 10, width: 10, backgroundColor: chartLegend.colour, marginRight:9, borderColor: chartLegend.borderColour }}></span>
                        {chartLegend.text}
                    </Typography>
                </Box>
            </Grid>
            );
    })

    return <Card sx={{ height: 195,width: 310, bgcolor: '#222222', color: '#222222',textAlign:'left'}}>
                { chartConfig &&
                    <Box style={{padding:'8px 16px 16px 16px'}}>
                        <Box sx={{ height: "20px",color:'white', textAlign:'center'}}>
                            <span><Typography variant="h6" style={{fontSize:10}}>{props.topText}</Typography></span>
                        </Box>
                        <Box 
                         display="flex"
                          justifyContent="center"
                          alignItems="center"
                          onMouseOver={ (e:any) =>  { setIsMouseOverChart(true)  }}  
                          onMouseLeave={ (e:any) => { setIsMouseOverChart(false) }}
                        >
                            <Box sx={{ height: 120,width: 210,position:'relative'}} >  
                                { !isMouseOverChart &&
                                    <React.Fragment>
                                        <div style={{position:'absolute',bottom:30,color: 'white',width:'100%',textAlign:'center'}} >        
                                            <Typography variant="h6" style={{fontSize:34}}>{props.centreBigText}</Typography>
                                        </div>
                                        <div style={{position:'absolute',bottom:18,width:'100%',color: 'white',textAlign:'center'}} >        
                                            <Typography variant="h6" style={{fontSize:14}}>{props.centerSmallText}</Typography>
                                        </div>
                                    </React.Fragment>
                                }
                                <Box sx={{ height: 'calc(100% - 20px)',width: '100%'}} >
                                    <DoughnutChart chartConfig={chartConfig} backgroundColor={'#222222'}/>
                                </Box>
                                <Box sx={{ height: "10px",color:'white'}}>
                                    <span style={{float:'left'}}> <Typography variant="h6" style={{fontSize:10}}>0</Typography></span>
                                    <span style={{float:'right'}}><Typography variant="h6" style={{fontSize:10}}>{props.totalCount}</Typography></span>
                                </Box>
               
                            </Box>
                        </Box>
                        { props.chartLegends.length > 0 &&
                            <Grid 
                              container
                              direction="row"
                              justifyContent="space-between"
                              alignItems="flex-start"                        
                            >
                             {chartLegendItems}
                            </Grid>
                        }  
                    </Box>
                }
           </Card>
}